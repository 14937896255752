import React from 'react'
import Transparent from './images/transparent.gif'


export default function AddSuper(props) {
   

return (
<React.Fragment>
 <div class="total_all">

    <div class="search-wrap" id="userSearchUl" style={{}}>
            <div>
                <input onChange = {(e)=>{props.searchItem(e.target.value);}} class="search-input" type="text" name="userId" id="userId" placeholder="Find member..."/>
                <button onClick = {()=>{props.searchBtnClicked()}} class="search-but" id="searchUserId">Search</button>
            </div>
        </div>
        <ul class="input-list" id="accountStatusUl">
            <li><strong>Status</strong></li>
            <li>
                <select class="" name="accountStatus" id="accountStatus">
                    
                    <option value="0">ACTIVE
                    </option>
                    
                    <option value="1">SUSPENDED
                    </option>
                    
                    <option value="2">LOCKED
                    </option>
                    
                    <option value="-1">ALL</option>
                </select>
            </li>
        </ul>
        

<div class="agent_path">
<ul id="agentPath" class="agent_path-L">

{props.agentStack.map((item,index)=>{
  var levelname;
  if(item.level == 0){
    levelname = 'ML';
}
  if(item.level == 1){
      levelname = 'WL';
  }
  else if(item.level == 2){
      levelname = 'SSS';
  }
  else if(item.level == 3){
      levelname = 'SS';
  }
  else if(item.level == 4){
      levelname = 'SUP';
  }
  else if(item.level == 5){
      levelname = 'MA';   
 }
 else if(item.level == 6){
    levelname = 'PL';
}


  if(props.agentStack.length < 2) return;
  return( 
    <li key = {index} id="path5" className={`${(index == props.agentStack.length-1) ? "last_li": ""}`}>
    <a onClick = {()=>{if(index == props.agentStack.length-1){return;} if(index === 0){props.changeUpdatedStatus();} props.popAgent(index);if(props.view === 2 || props.view === 3){props.changeView(1);}}}>
       <span className={`lv_${item.level !== '6' ? item.level:"0"}`}>
         {levelname}
        </span>
        <strong>{item.userid}</strong>
       </a>
     </li>

    )})}

 <ul class="account_pop" id="accountPop">
    <li id="popTmp" style={{}}>
        <a></a>
    </li>
   </ul>
  </ul>
 </div>


 
 {props.userLevel === 0 && props.view === 1 && <a  style={{marginRight:"2px",padding:"0px 6px 0px 6px",marginBottom:"12px"}} class="add_member"  onClick={()=>{props.HandlePopup(4,true)}}><img src={Transparent}/>Add White Level</a>} 
 {props.userLevel === 0 && props.view === 1 && <a  style={{marginRight:"2px",padding:"0px 6px 0px 6px",marginBottom:"12px"}} class="add_member"  onClick={()=>{props.HandlePopup(4,true)}}><img src={Transparent}/>Add House</a>} 
 {props.userLevel === 1  && props.view === 1 && <a  style={{marginRight:"2px",padding:"0px 6px 0px 6px",marginBottom:"12px"}} class="add_member"  onClick={()=>{props.HandlePopup(4,true)}}><img src={Transparent}/>Add Super Admin</a>} 
 {props.userLevel  === 2  && props.view === 1 && <a  style={{marginRight:"2px",padding:"0px 6px 0px 6px",marginBottom:"12px"}} class="add_member"  onClick={()=>{props.HandlePopup(4,true)}}><img src={Transparent}/>Add Super Super</a>} 
 {props.userLevel === 3 && props.view === 1 && <a  style={{marginRight:"2px",padding:"0px 6px 0px 6px",marginBottom:"12px"}} class="add_member"  onClick={()=>{props.HandlePopup(4,true)}}><img src={Transparent}/>Add Super</a>} 
 {props.userLevel === 4 && props.view === 1 && <a  style={{marginRight:"2px",padding:"0px 6px 0px 6px",marginBottom:"12px"}} class="add_member"  onClick={()=>{props.HandlePopup(4,true)}}><img src={Transparent}/>Add Master</a>} 
{props.view === 1 && props.userLevel === 5 && <a style={{marginRight:"2px",padding:"0px 6px 0px 6px",marginBottom:"12px"}} class="add_member"  onClick={()=>{props.HandlePopup(2,true)}}><img src={Transparent}/>Add Player</a>}


   </div>
  </React.Fragment>
 )
}
